import React, { useEffect, useState } from "react";
import api from "../api";
import { useParams } from "react-router-dom";
import { 
  Container, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  Divider, 
  Button,
  Paper,
  Grid,
  Box,
  styled
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { saveAs } from 'file-saver';

// Custom styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const DatePickerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const Inferences = () => {
  const { modelId } = useParams();
  const [inferences, setInferences] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchInferences = async () => {
    try {
      const response = await api.get(`/models/${modelId}/inferences`);
      setInferences(response.data.inferences);
    } catch (error) {
      console.error("Error fetching inferences:", error);
    }
  };

  const downloadCSV = () => {
    const csvRows = [
      ['File Name', 'Top Prediction', 'Timestamp']
    ];

    const filteredInferences = inferences.filter((inference) => {
      const inferenceDate = new Date(inference.timestamp);
      const isAfterStart = !startDate || inferenceDate >= startDate;
      const isBeforeEnd = !endDate || inferenceDate <= endDate;
      return isAfterStart && isBeforeEnd;
    });

    filteredInferences.forEach(inference => {
      const topPrediction = inference.predictions[0];
      const formattedPrediction = `${topPrediction.class_name} (${(topPrediction.similarity * 100).toFixed(2)}%)`;
      const timestamp = new Date(inference.timestamp).toLocaleString();
      csvRows.push([inference.file_name, formattedPrediction, timestamp]);
    });

    const csvContent = csvRows.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `inferences_report_${new Date().toLocaleDateString()}.csv`);
  };

  useEffect(() => {
    fetchInferences();
  }, [modelId]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ pt: 8, pb: 4, mt: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          Inference History
        </Typography>

        <StyledPaper elevation={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DatePickerContainer>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box sx={{ flex: 1 }}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                </LocalizationProvider>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={downloadCSV}
                  sx={{ 
                    minWidth: 200,
                    height: 56  // Match DatePicker height
                  }}
                >
                  Download CSV Report
                </Button>
              </DatePickerContainer>
            </Grid>
          </Grid>
        </StyledPaper>

        {inferences.length === 0 ? (
          <Typography align="center" color="textSecondary">
            No inferences available for this model.
          </Typography>
        ) : (
          <Paper elevation={2}>
            <List>
              {inferences.map((inference, index) => (
                <React.Fragment key={index}>
                  <StyledListItem>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                              File: {inference.file_name}
                            </Typography>
                          }
                          secondary={
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                              Top Prediction: {inference.predictions.length > 0 ? 
                                `${inference.predictions[0].class_name} (${(inference.predictions[0].similarity * 100).toFixed(2)}%)`
                                : 'No predictions'}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Typography variant="body2" color="textSecondary">
                            {new Date(inference.timestamp).toLocaleString()}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledListItem>
                  {index < inferences.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default Inferences;