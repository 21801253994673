// src/components/GenerateImages.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Snackbar,
  IconButton,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Dialog,
  DialogContent,
  Tooltip,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import BrushIcon from '@mui/icons-material/Brush';
import PhotoIcon from '@mui/icons-material/Photo';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import CreateIcon from '@mui/icons-material/Create';
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ComputerIcon from '@mui/icons-material/Computer';
import PaletteIcon from '@mui/icons-material/Palette';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import CastleIcon from '@mui/icons-material/Castle';
import SettingsIcon from '@mui/icons-material/Settings';
import ScienceIcon from '@mui/icons-material/Science';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MinimizeIcon from '@mui/icons-material/Minimize';
import ClearIcon from '@mui/icons-material/Clear';
import api from "../api"; // Import your API configuration
import AuthContext from "../context/AuthContext"; // Import AuthContext

// Define all supported styles with corresponding icons
const stylesOptions = [
  { label: "None", value: "", icon: <ClearIcon /> },
  { label: "Vector", value: "vector", icon: <CropSquareIcon /> },
  { label: "Painting", value: "painting", icon: <BrushIcon /> },
  { label: "Photography", value: "photography", icon: <PhotoIcon /> },
  { label: "3D", value: "3d", icon: <ThreeDRotationIcon /> },
  { label: "Digital Art", value: "digital_art", icon: <ColorLensIcon /> },
  { label: "Anime", value: "anime", icon: <EmojiEmotionsIcon /> },
  { label: "Sketch/Drawing", value: "sketch_drawing", icon: <CreateIcon /> },
  { label: "Low-Poly", value: "low_poly", icon: <GridOnIcon /> },
  { label: "Isometric", value: "isometric", icon: <ViewInArIcon /> },
  { label: "Surrealism", value: "surrealism", icon: <PsychologyIcon /> },
  { label: "Cyberpunk", value: "cyberpunk", icon: <ComputerIcon /> },
  { label: "Impressionism", value: "impressionism", icon: <PaletteIcon /> },
  { label: "Pop Art", value: "pop_art", icon: <InsertEmoticonIcon /> },
  { label: "Pixel Art", value: "pixel_art", icon: <GridOnIcon /> },
  { label: "Realism", value: "realism", icon: <VisibilityIcon /> },
  { label: "Cartoon", value: "cartoon", icon: <EmojiPeopleIcon /> },
  { label: "Abstract", value: "abstract", icon: <BlurOnIcon /> },
  { label: "Fantasy", value: "fantasy", icon: <CastleIcon /> },
  { label: "Steampunk", value: "steampunk", icon: <SettingsIcon /> },
  { label: "Sci-Fi", value: "sci_fi", icon: <ScienceIcon /> },
  { label: "Comic/Graphic Novel", value: "comic_graphic_novel", icon: <MenuBookIcon /> },
  { label: "Baroque/Renaissance", value: "baroque_renaissance", icon: <AccountBalanceIcon /> },
  { label: "Minimalism", value: "minimalism", icon: <MinimizeIcon /> },
];

const GenerateImages = () => {
  // Existing state variables
  const [prompt, setPrompt] = useState(""); // Description
  const [exclusions, setExclusions] = useState(""); // Exclusions
  const [initImage, setInitImage] = useState(null); // Initial image for image editing
  const [influenceLevel, setInfluenceLevel] = useState(0.7); // Influence level for image editing
  const [generatedImage, setGeneratedImage] = useState(null); // Generated image (base64)
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error state
  const [generateMode, setGenerateMode] = useState("text"); // Mode: "text" or "image_editing"
  const [aspectRatio, setAspectRatio] = useState("1:1"); // Aspect ratio (for text mode)
  const [outputFormat, setOutputFormat] = useState("jpeg"); // Output format
  const [randomSeed, setRandomSeed] = useState(0); // Random seed for generation
  const [selectedImage, setSelectedImage] = useState(null); // For image viewer dialog
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar for errors
  const [selectedStyle, setSelectedStyle] = useState(""); // Selected style
  const [removeBackground, setRemoveBackground] = useState(false); // Remove Background option
  const [remainingGenerations, setRemainingGenerations] = useState(5); // Remaining generations for unauthenticated users
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true); // New state for card visibility
  const [imageProcessing, setImageProcessing] = useState(false); // Image processing state
  const [weight, setWeight] = useState(1.0); // Weight for prompt intensity

  // NEW STATE VARIABLES FOR BACKGROUND REPLACEMENT
  const [replaceBackground, setReplaceBackground] = useState(false); // Replace Background option
  const [backgroundType, setBackgroundType] = useState("prompt"); // "prompt" or "image"
  const [backgroundPrompt, setBackgroundPrompt] = useState(""); // Background prompt
  const [backgroundImage, setBackgroundImage] = useState(null); // Background image file
  const [foregroundPrompt, setForegroundPrompt] = useState(""); // Foreground prompt
  const [preserveOriginalSubject, setPreserveOriginalSubject] = useState(0.6); // Subject preservation level
  const [keepOriginalBackground, setKeepOriginalBackground] = useState(false); // Keep original background option
  const [lightSourceStrength, setLightSourceStrength] = useState(0.3); // Light source strength
  const [lightSourceDirection, setLightSourceDirection] = useState("none"); // Light source direction
  const [lightReferenceImage, setLightReferenceImage] = useState(null); // Light reference image

  const navigate = useNavigate();
  const location = useLocation();
  const { generationPrompt, from } = location.state || {};
  const { user } = useContext(AuthContext);

  const GENERATE_LIMIT = 5; // Set the limit for image generations

  useEffect(() => {
    if (!user) {
      const generateCount = parseInt(localStorage.getItem("generateCount")) || 0;
      setRemainingGenerations(GENERATE_LIMIT - generateCount);
    }
  }, [user]);

  useEffect(() => {
    if (generationPrompt) {
      setPrompt(generationPrompt);
    }
  }, [generationPrompt]);

  // Function to check if the user has reached the generation limit
  const checkGenerateLimit = () => {
    if (!user) {
      const generateCount = parseInt(localStorage.getItem("generateCount")) || 0;
      if (generateCount >= GENERATE_LIMIT) {
        alert("You have reached the limit of free image generations. Please sign up or log in to continue.");
        navigate("/login"); // Redirect to login/signup page
        return true; // Limit reached
      }
    }
    return false; // Limit not reached
  };

  // Handle mode switch
  const handleGenerateMode = (event, newMode) => {
    if (newMode !== null) {
      setGenerateMode(newMode);
      setGeneratedImage(null); // Clear previous results when mode is switched
      setError("");
      setPrompt(""); // Reset the description
      setExclusions("");
      setInitImage(null); // Reset the image input
      setInfluenceLevel(0.7); // Reset influence level
      setSelectedStyle(""); // Reset selected style
      setRemoveBackground(false); // Reset remove background option
      setReplaceBackground(false); // Reset replace background option
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle style selection
  const handleStyleChange = (event) => {
    setSelectedStyle(event.target.value);
  };

  useEffect(() => {
    if (removeBackground) {
      // Set output format to png when background removal is selected
      setOutputFormat("png");
    }
  }, [removeBackground]);

  // Function to resize image to a maximum of 1 million pixels
  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const MAX_PIXELS = 1000000; // 1 million pixels
        const totalPixels = img.width * img.height;

        if (totalPixels <= MAX_PIXELS) {
          // No resizing needed
          resolve(file);
        } else {
          // Compute scaling factor
          const scalingFactor = Math.sqrt(MAX_PIXELS / totalPixels);
          const newWidth = Math.round(img.width * scalingFactor);
          const newHeight = Math.round(img.height * scalingFactor);

          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          }, file.type);
        }
      };
      img.onerror = () => {
        reject(new Error("Failed to process image."));
      };
      img.src = URL.createObjectURL(file);
    });
  };

  // Handle image upload and resizing for the main image
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageProcessing(true);
      try {
        const resizedFile = await resizeImage(file);
        setInitImage(resizedFile);
      } catch (error) {
        console.error("Error processing image:", error);
        setError("Error processing image. Please try again.");
        setSnackbarOpen(true);
      } finally {
        setImageProcessing(false);
      }
    }
  };

  // Handle background image upload and resizing
  const handleBackgroundImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const resizedFile = await resizeImage(file);
        setBackgroundImage(resizedFile);
      } catch (error) {
        console.error("Error processing background image:", error);
        setError("Error processing background image. Please try again.");
        setSnackbarOpen(true);
      }
    }
  };

  // Function to generate images
  const generateImages = async () => {
    // Check if generation limit is reached
    if (!user && checkGenerateLimit()) {
      return; // Exit if the limit is reached
    }

    setLoading(true);
    setError("");

    const formData = new FormData();

    try {
      let finalPrompt = prompt.trim();
      if (selectedStyle) {
        finalPrompt += ` in ${selectedStyle.replace("_", " ")} style`;
      }

      if (generateMode === "text") {
        // Text-based Generation
        if (!prompt || prompt.trim() === "") {
          setError("Please enter a description for the image.");
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }

        formData.append("prompt", finalPrompt);
        formData.append("exclusions", exclusions);
        formData.append("aspect_ratio", aspectRatio);
        formData.append("output_format", outputFormat);
        formData.append("random_seed", randomSeed);
        formData.append("image_count", "1");
        formData.append("weight", weight.toString());

        const response = await api.post("/generate-image", formData, {
          responseType: "arraybuffer",
        });

        const contentType = response.headers["content-type"];
        const blob = new Blob([response.data], { type: contentType });
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result.split(",")[1];
          setGeneratedImage(base64data);
        };
        reader.readAsDataURL(blob);

      } else if (generateMode === "image_editing") {
        if (!initImage) {
          setError("Please upload an image.");
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }

        // Remove Background Mode
        if (removeBackground) {
          // Validate image type
          const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
          if (!allowedTypes.includes(initImage.type)) {
            setError("Only JPG, PNG and WEBP images are supported for background replacement.");
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }

          if (!["png", "webp"].includes(outputFormat)) {
            setOutputFormat("png");
          }
          formData.append("output_format", outputFormat);
          formData.append("image", initImage);

          const response = await api.post("/remove-background", formData, {
            responseType: "arraybuffer",
          });

          const contentType = response.headers["content-type"];
          const blob = new Blob([response.data], { type: contentType });
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result.split(",")[1];
            setGeneratedImage(base64data);
          };
          reader.readAsDataURL(blob);

        } else if (replaceBackground) {
          // Background Replacement Mode
          // Validate main image type
          const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
          if (!allowedTypes.includes(initImage.type)) {
            setError("Only JPG, PNG and WEBP images are supported for background replacement.");
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }

          // Validate background requirements
          if (backgroundType === "prompt" && !backgroundPrompt) {
            setError("Please enter a background description.");
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }
          if (backgroundType === "image" && !backgroundImage) {
            setError("Please upload a background image.");
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }

          try {
            // Append form data for background replacement
            formData.append("output_format", outputFormat);
            formData.append("image", initImage);  // Main image

            // Background options
            if (backgroundType === "prompt") {
              formData.append("background_prompt", backgroundPrompt);
            } else if (backgroundImage) {
              formData.append("background_image", backgroundImage);
            }

            // Optional parameters
            if (foregroundPrompt) {
              formData.append("foreground_prompt", foregroundPrompt);
            }
            if (exclusions) {
              formData.append("negative_prompt", exclusions);
            }

            formData.append("preserve_original_subject", preserveOriginalSubject.toString());
            formData.append("original_background_depth", "0.5");  // Default value
            formData.append("keep_original_background", keepOriginalBackground.toString());

            // Light source parameters
            if (lightSourceDirection !== "none") {
              formData.append("light_source_direction", lightSourceDirection);
            }
            if (lightSourceStrength > 0) {
              formData.append("light_source_strength", lightSourceStrength.toString());
            }

            // Random seed if provided
            if (randomSeed) {
              formData.append("seed", randomSeed.toString());
            }

            const response = await api.post("/replace-background", formData, {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            const contentType = response.headers["content-type"];
            const blob = new Blob([response.data], { type: contentType });
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result.split(",")[1];
              setGeneratedImage(base64data);
            };
            reader.readAsDataURL(blob);

          } catch (error) {
            console.error("Error replacing background:", error);
            if (error.response) {
              console.error("Response data:", error.response.data);
              console.error("Response status:", error.response.status);
            }
            setError("Error replacing background. Please try again.");
            setSnackbarOpen(true);
          }
        } else {
          // Existing Image Editing Code
          if (!prompt || prompt.trim() === "") {
            setError("Please enter a description for the modifications.");
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }

          formData.append("prompt", finalPrompt);
          formData.append("exclusions", exclusions);
          formData.append("influence_level", influenceLevel);
          formData.append("output_format", outputFormat);
          formData.append("random_seed", randomSeed);
          formData.append("image", initImage);

          const response = await api.post("/control-image", formData, {
            responseType: "arraybuffer",
          });

          const contentType = response.headers["content-type"];
          const blob = new Blob([response.data], { type: contentType });
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result.split(",")[1];
            setGeneratedImage(base64data);
          };
          reader.readAsDataURL(blob);
        }

        // Increment generation count for unauthenticated users
        if (!user) {
          const generateCount = parseInt(localStorage.getItem("generateCount")) || 0;
          localStorage.setItem("generateCount", generateCount + 1);
          setRemainingGenerations(GENERATE_LIMIT - (generateCount + 1));
        }
      }
    } catch (error) {
      console.error("Error generating image:", error);
      setError("Error generating image. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Update the downloadImage function to handle multiple images:
  const downloadImage = () => {
    if (generatedImage) {
      const link = document.createElement("a");
      link.href = `data:image/${outputFormat};base64,${generatedImage}`;
      link.download = `generated_image.${outputFormat}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: "5rem", marginBottom: "4rem" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          marginBottom: "1rem",
          color: "#550FCC",
          textAlign: "center",
        }}
      >
        🎨 Generate Images
      </Typography>

      {/* Display remaining generations for unauthenticated users */}
      {!user && (
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ textAlign: "center", marginBottom: "1rem" }}
        >
          You have {remainingGenerations} image generation{remainingGenerations !== 1 ? "s" : ""} remaining.
        </Typography>
      )}

      {/* Error Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={error}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      {/* Toggle between modes */}
      <ToggleButtonGroup
        color="primary"
        value={generateMode}
        exclusive
        onChange={handleGenerateMode}
        sx={{ marginBottom: "1.5rem", display: "flex", justifyContent: "center" }}
      >
        <ToggleButton value="text">📝 Text Prompt</ToggleButton>
        <ToggleButton value="image_editing">🖼️ Image Editing</ToggleButton>
      </ToggleButtonGroup>

      {/* Style Selector */}
      <Box sx={{ textAlign: "center", marginBottom: "2rem" }}>
        <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
          Choose a Style
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="style-select-label">Style</InputLabel>
          <Select
            labelId="style-select-label"
            id="style-select"
            value={selectedStyle}
            label="Style"
            onChange={handleStyleChange}
            renderValue={(selected) => {
              if (!selected) {
                return "None";
              }
              const style = stylesOptions.find((option) => option.value === selected);
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {style.icon}
                  <Typography sx={{ marginLeft: "8px" }}>{style.label}</Typography>
                </Box>
              );
            }}
          >
            {stylesOptions.map((style) => (
              <MenuItem key={style.value} value={style.value}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {style.icon}
                  <Typography sx={{ marginLeft: "8px" }}>{style.label}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Text-Based Generation */}
      {generateMode === "text" && (
        <>
          <TextField
            fullWidth
            label="Description"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            variant="outlined"
            sx={{ marginBottom: "1rem" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  📝
                </InputAdornment>
              ),
            }}
            helperText="Enter a description for the image."
          />
          <TextField
            fullWidth
            label="Exclusions"
            value={exclusions}
            onChange={(e) => setExclusions(e.target.value)}
            variant="outlined"
            sx={{ marginBottom: "1rem" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  🚫
                </InputAdornment>
              ),
            }}
            helperText="Optional: Specify what you don't want in the image."
          />
          {/* Options for aspect ratio, output format, and random seed */}
          <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="aspect-ratio-label">📐 Aspect Ratio</InputLabel>
                <Select
                  labelId="aspect-ratio-label"
                  value={aspectRatio}
                  label="📐 Aspect Ratio"
                  onChange={(e) => setAspectRatio(e.target.value)}
                >
                  {[
                    "21:9",
                    "16:9",
                    "3:2",
                    "5:4",
                    "1:1",
                    "4:5",
                    "2:3",
                    "9:16",
                    "9:21",
                  ].map((ratio) => (
                    <MenuItem value={ratio} key={ratio}>
                      {ratio}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="output-format-label">📁 Output Format</InputLabel>
                <Select
                  labelId="output-format-label"
                  value={outputFormat}
                  label="📁 Output Format"
                  onChange={(e) => setOutputFormat(e.target.value)}
                >
                  {["jpeg", "png", "webp"].map((format) => (
                    <MenuItem value={format} key={format}>
                      {format.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Random Seed"
                type="number"
                value={randomSeed}
                onChange={(e) => setRandomSeed(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      🎲
                    </InputAdornment>
                  ),
                }}
                helperText="Optional: Set a seed for reproducibility."
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Prompt Weight"
                type="number"
                inputProps={{ min: 0, max: 1, step: 0.1 }}
                value={weight}
                onChange={(e) => setWeight(parseFloat(e.target.value))}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      ⚖️
                    </InputAdornment>
                  ),
                }}
                helperText="Adjust prompt intensity (0.0 - 1.0)"
              />
            </Grid>
          </Grid>
        </>
      )}

      {/* Image Editing Generation */}
      {generateMode === "image_editing" && (
        <>
          {/* Background Options */}
          <Box sx={{ marginBottom: "1rem" }}>
            <Grid container spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={removeBackground}
                      onChange={(e) => {
                        setRemoveBackground(e.target.checked);
                        if (e.target.checked) {
                          setReplaceBackground(false);
                        }
                      }}
                      name="removeBackground"
                      color="primary"
                    />
                  }
                  label="Remove Background"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={replaceBackground}
                      onChange={(e) => {
                        setReplaceBackground(e.target.checked);
                        if (e.target.checked) {
                          setRemoveBackground(false);
                        }
                      }}
                      name="replaceBackground"
                      color="primary"
                    />
                  }
                  label="Replace Background"
                />
              </Grid>
            </Grid>
          </Box>

          {/* If neither Remove nor Replace Background is active, show modification fields */}
          {!removeBackground && !replaceBackground && (
            <>
              <TextField
                fullWidth
                label="Description"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                variant="outlined"
                sx={{ marginBottom: "1rem" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      📝
                    </InputAdornment>
                  ),
                }}
                helperText="Enter a description for the desired modifications."
              />
              <TextField
                fullWidth
                label="Exclusions"
                value={exclusions}
                onChange={(e) => setExclusions(e.target.value)}
                variant="outlined"
                sx={{ marginBottom: "1rem" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      🚫
                    </InputAdornment>
                  ),
                }}
                helperText="Optional: Specify what you don't want in the image."
              />
              {/* Influence Level */}
              <Box sx={{ marginBottom: "1rem" }}>
                <TextField
                  fullWidth
                  label="Influence Level"
                  type="number"
                  inputProps={{ min: 0, max: 1, step: 0.05 }}
                  value={influenceLevel}
                  onChange={(e) => setInfluenceLevel(parseFloat(e.target.value))}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        🎛️
                      </InputAdornment>
                    ),
                  }}
                  helperText="Adjust how much the initial image influences the generation (0.0 - 1.0)."
                />
              </Box>
            </>
          )}

          {/* Background Replacement Options */}
          {replaceBackground && (
            <Box sx={{ marginBottom: "2rem" }}>
              <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                Background Replacement Options
              </Typography>

              {/* Background Type Selection */}
              <FormControl component="fieldset" sx={{ marginBottom: "1rem" }}>
                <ToggleButtonGroup
                  value={backgroundType}
                  exclusive
                  onChange={(e, newValue) => {
                    if (newValue !== null) {
                      setBackgroundType(newValue);
                      if (newValue === "prompt") {
                        setBackgroundImage(null);
                      } else {
                        setBackgroundPrompt("");
                      }
                    }
                  }}
                >
                  <ToggleButton value="prompt">Text Prompt</ToggleButton>
                  <ToggleButton value="image">Reference Image</ToggleButton>
                </ToggleButtonGroup>
              </FormControl>

              {/* Background Prompt Input */}
              {backgroundType === "prompt" && (
                <TextField
                  fullWidth
                  label="Background Description"
                  value={backgroundPrompt}
                  onChange={(e) => setBackgroundPrompt(e.target.value)}
                  variant="outlined"
                  sx={{ marginBottom: "1rem" }}
                  helperText="Describe the background you want"
                />
              )}

              {/* Background Image Upload */}
              {backgroundType === "image" && (
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography variant="body1" sx={{ marginBottom: "0.5rem" }}>
                    Upload Background Image
                  </Typography>
                  <input
                    type="file"
                    accept="image/jpeg,image/png,image/webp"
                    onChange={handleBackgroundImageUpload}
                  />
                  {backgroundImage && (
                    <Typography variant="body2" color="textSecondary">
                      Selected background: {backgroundImage.name}
                    </Typography>
                  )}
                </Box>
              )}

              {/* Advanced Options */}
              <Typography variant="h6" sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                Advanced Options
              </Typography>

              <Grid container spacing={2}>
                {/* Subject Preservation */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Subject Preservation"
                    type="number"
                    inputProps={{ min: 0, max: 1, step: 0.1 }}
                    value={preserveOriginalSubject}
                    onChange={(e) => setPreserveOriginalSubject(Number(e.target.value))}
                    variant="outlined"
                    helperText="How much to preserve the original subject (0-1)"
                  />
                </Grid>

                {/* Keep Original Background */}
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={keepOriginalBackground}
                        onChange={(e) => setKeepOriginalBackground(e.target.checked)}
                      />
                    }
                    label="Keep Original Background"
                  />
                </Grid>

                {/* Light Source Strength */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Light Source Strength"
                    type="number"
                    inputProps={{ min: 0, max: 1, step: 0.1 }}
                    value={lightSourceStrength}
                    onChange={(e) => setLightSourceStrength(Number(e.target.value))}
                    variant="outlined"
                    helperText="Strength of lighting effect (0-1)"
                  />
                </Grid>

                {/* Light Source Direction */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Light Direction</InputLabel>
                    <Select
                      value={lightSourceDirection}
                      onChange={(e) => setLightSourceDirection(e.target.value)}
                      label="Light Direction"
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="above">Above</MenuItem>
                      <MenuItem value="below">Below</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Foreground Prompt */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Foreground Prompt"
                    value={foregroundPrompt}
                    onChange={(e) => setForegroundPrompt(e.target.value)}
                    variant="outlined"
                    helperText="Optional: Describe how to enhance the foreground subject"
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Input for image upload */}
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography variant="body1" sx={{ marginBottom: "0.5rem" }}>
              🖼️ Upload Image <Tooltip title="Provide an image to modify">ℹ️</Tooltip>
            </Typography>
            <input
              type="file"
              accept={replaceBackground || removeBackground ? "image/jpeg, image/png, image/webp" : "image/*"}
              onChange={handleImageUpload}
            />
            {imageProcessing ? (
              <Typography variant="body2" color="textSecondary">
                Processing image...
              </Typography>
            ) : (
              initImage && (
                <Typography variant="body2" color="textSecondary">
                  Selected image: {initImage.name}
                </Typography>
              )
            )}
          </Box>

          {/* Options for output format and random seed */}
          <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="output-format-label">📁 Output Format</InputLabel>
                <Select
                  labelId="output-format-label"
                  value={
                    (removeBackground || replaceBackground) && !["png", "webp"].includes(outputFormat)
                      ? "png"
                      : outputFormat
                  }
                  label="📁 Output Format"
                  onChange={(e) => setOutputFormat(e.target.value)}
                >
                  {replaceBackground || removeBackground
                    ? ["png", "webp"].map((format) => (
                        <MenuItem value={format} key={format}>
                          {format.toUpperCase()}
                        </MenuItem>
                      ))
                    : ["jpeg", "png", "webp"].map((format) => (
                        <MenuItem value={format} key={format}>
                          {format.toUpperCase()}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Random Seed"
                type="number"
                value={randomSeed}
                onChange={(e) => setRandomSeed(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      🎲
                    </InputAdornment>
                  ),
                }}
                helperText="Optional: Set a seed for reproducibility."
              />
            </Grid>
          </Grid>
        </>
      )}

      {/* Generate Images button */}
      <Box sx={{ textAlign: "center", marginBottom: "2rem" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={generateImages}
          disabled={loading}
          sx={{
            padding: "10px 20px",
            borderRadius: "8px",
            minWidth: "200px",
            height: "50px",
            fontSize: "1rem",
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Generate Image"}
        </Button>
      </Box>

      {/* Display generated image */}
      {generatedImage && (
        <Card sx={{ marginBottom: "2rem" }}>
          <CardContent>
            {Array.isArray(generatedImage) ? (
              <Grid container spacing={2}>
                {generatedImage.map((img, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <img
                      src={`data:image/${outputFormat};base64,${img}`}
                      alt={`Generated ${index + 1}`}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        marginBottom: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => setSelectedImage(img)}
                    />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => downloadImage(img, index)}
                        sx={{
                          padding: "10px 20px",
                          borderRadius: "8px",
                        }}
                      >
                        💾 Download Image {index + 1}
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <>
                <img
                  src={`data:image/${outputFormat};base64,${generatedImage}`}
                  alt="Generated"
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    marginBottom: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedImage(generatedImage)}
                />
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={downloadImage}
                    sx={{
                      padding: "10px 20px",
                      borderRadius: "8px",
                    }}
                  >
                    💾 Download Image
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      )}

      {/* Image Viewer Dialog */}
      <Dialog
        open={Boolean(selectedImage)}
        onClose={() => setSelectedImage(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {selectedImage && (
            <img
              src={`data:image/${outputFormat};base64,${selectedImage}`}
              alt="Selected"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Explanatory Card */}
      {showExplanatoryCard && (
        <Card
          sx={{
            width: "90%",
            maxWidth: "800px",
            margin: "2rem auto",
            padding: "2rem",
            borderRadius: "12px",
            backgroundColor: "#f9f9f9",
            position: "relative",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: "center" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              🖌️ Image Generation Tool
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ lineHeight: 1.6 }}>
              Use this tool to generate images based on a description or modify an existing image with Image Editing.
              Select a style to customize the appearance of your image, and adjust the settings as needed.
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default GenerateImages;
