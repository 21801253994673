import React, { useState } from 'react';
import { Container, Typography, Box, Tabs, Tab } from '@mui/material';
import SearchAndRetrieveWebImages from './SearchAndRetrieveWebImages';  // Existing component
import GenerateImages from './GenerateImages';  // Existing component
import DesignGenerator from './DesignGenerator';

const PublicFeatures = () => {
  const [activeTab, setActiveTab] = useState(0); // State to keep track of the selected tab

  // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: '6rem', marginBottom: '4rem' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          variant="h4"
          sx={{
            marginBottom: '2rem',
            color: '#550FCC',
            fontWeight: 'bold',
          }}
        >
          Try Free Netra.AI Features - Login/Sign Up to Unlock Full Suite
        </Typography>
      </Box>

      {/* Tabs for switching between features */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{ marginBottom: '2rem' }}
      >
        <Tab label="Search Web Images" />
        <Tab label="Generate Images" />
        <Tab label="Design Copilot" />
      </Tabs>

      {/* Conditionally render based on the selected tab */}
      <Box>
        {activeTab === 0 && <SearchAndRetrieveWebImages />}
        {activeTab === 1 && <GenerateImages />}
        {activeTab === 2 && <DesignGenerator />}
      </Box>
    </Container>
  );
};

export default PublicFeatures;
