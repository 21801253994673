import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Card, // Added
  CardContent, // Added
  IconButton, // Added
} from "@mui/material"; // Import Select and MenuItem components
import AuthContext from "../context/AuthContext";
import AddIcon from "@mui/icons-material/AddPhotoAlternate";  // Suitable icon for the explanatory section
import CloseIcon from "@mui/icons-material/Close"; // Added Close icon
import Joyride from "react-joyride"; // Import react-joyride for tutorial

const AddClass = ({ modelId, modelName }) => {
  const [className, setClassName] = useState("");
  const [reasoning, setReasoning] = useState("");  // State to capture explanation/reasoning
  const [files, setFiles] = useState([]);
  const [embeddingChoice, setEmbeddingChoice] = useState("image");  // State to select embedding method (image or combined)
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [loadingDescription, setLoadingDescription] = useState(false); // Loading state for generating description
  const [isClassLoading, setIsClassLoading] = useState(false); // Loading state for adding class
  const [isDescriptionGenerated, setIsDescriptionGenerated] = useState(false); // State to track if description is generated
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true); // New state for card visibility
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);  // Get the user (username) from AuthContext

  // Tutorial steps (conditionally adding "Generate Explanation" if embedding is combined)
  const tutorialSteps = [
    {
      target: ".class-name-field",
      content: "Enter the name of the class you want to add.",
    },
    {
      target: ".file-upload",
      content: "Upload example images for this class.",
    },
    {
      target: ".embedding-choice",
      content: "Select the model training method for the class.",
    },
    // Conditionally add this step if "combined" is selected
    ...(embeddingChoice === "combined" ? [{
      target: ".generate-explanation-button",
      content: "Click here to generate an explanation for the class."
    }] : []),
    {
      target: ".add-class-button",
      content: "Click here to add the class.",
    },
  ];

  // Function to add the class with files
  const addClass = async () => {
    if (!modelId) {
      alert("No model selected. Please create a model first OR select from existing models.");
      return;
    }

    setIsClassLoading(true); // Set loading while adding the class

    const formData = new FormData();
    formData.append("name", className);
    formData.append("reasoning", reasoning);  // Append reasoning to the formData
    formData.append("embedding_choice", embeddingChoice); // Append the embedding choice

    if (files.length > 0) {
      for (const file of files) {
        formData.append("files", file);
      }
    }

    try {
      const response = await api.post(`/models/${modelId}/classes`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setIsClassAdded(true);
      alert(`Class Added: ${response.data.class_name}`);
    } catch (error) {
      alert("Error adding class");
    } finally {
      setIsClassLoading(false); // Stop loading after class is added
    }
  };

  // Function to generate explanation using GPT-4o-mini (sending only the first image)
  const generateExplanation = async () => {
    if (files.length === 0 || !className) {
      alert("Please upload at least one image and provide a class name before generating a description.");
      return;
    }

    setLoadingDescription(true); // Set loading while generating explanation

    const formData = new FormData();
    formData.append("file", files[0]);  // Send only the first image
    formData.append("class_name", className);

    try {
      const response = await api.post("/generate-description", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setReasoning(response.data.description); // Set generated description in the reasoning field
      setIsDescriptionGenerated(true); // Mark description as generated
    } catch (error) {
      alert("Error generating description");
    } finally {
      setLoadingDescription(false); // Stop loading after explanation is generated
    }
  };

  // Function to go to the prediction page
  const goToPredict = () => {
    navigate("/predict");
  };

  return (
    <Container 
      maxWidth="md"  // Widened the container
      sx={{ 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh", 
        padding: "1rem",
        paddingTop:'100px'
      }}
    >
      {/* Joyride tutorial overlay */}
      <Joyride steps={tutorialSteps} run={true} continuous showSkipButton />

      <Paper elevation={3} sx={{ padding: "2rem", width: "100%", borderRadius: "12px", mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center", color: "#550FCC", mb: 2 }}>
          Add Class to Model: {modelName}
        </Typography>

        <TextField
          fullWidth
          label="Class Name"
          variant="outlined"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          margin="normal"
          className="class-name-field"  // Add class for tutorial
          InputProps={{
            sx: { borderRadius: "8px" },
          }}
        />

        

        {/* New Dropdown to Select Embedding Choice */}
        <FormControl fullWidth sx={{ marginTop: "1rem" }} className="embedding-choice">
          <InputLabel id="embedding-choice-label">Model Training Type</InputLabel>
          <Select
            labelId="embedding-choice-label"
            value={embeddingChoice}
            onChange={(e) => setEmbeddingChoice(e.target.value)}
            label="Embedding Type"
          >
            <MenuItem value="image">Image Only</MenuItem>
            <MenuItem value="combined">Image + Text (Combined)</MenuItem>
          </Select>
        </FormControl>

        {/* File Upload */}
        <input
          type="file"
          multiple
          className="file-upload"
          onChange={(e) => setFiles(e.target.files)}
          style={{ marginTop: "1rem" }}
        />

        {/* Generate Explanation Button */}
        {embeddingChoice === "combined" && (
          <Box mt={2}>
            <Button 
              variant="contained" 
              color="secondary"
              onClick={generateExplanation}
              disabled={files.length === 0 || loadingDescription}  // Disable if no images are uploaded
              className="generate-explanation-button"  // Add class for tutorial
              sx={{
                borderRadius: "8px",
                backgroundColor: "#550FCC",
                '&:hover': {
                  backgroundColor: "#8338ec",
                },
              }}
            >
              {loadingDescription ? <CircularProgress size={24} /> : "Generate Explanation"}
            </Button>
            {isDescriptionGenerated && (
              <Typography mt={2} variant="body1" color="textSecondary">
                Description generated! You can modify it below.
              </Typography>
            )}
          </Box>
        )}

        <TextField
          fullWidth
          label="Explanation / Reasoning"
          variant="outlined"
          value={reasoning}
          onChange={(e) => setReasoning(e.target.value)}
          margin="normal"
          multiline
          rows={4}  // Multiline input for reasoning
          InputProps={{
            sx: { borderRadius: "8px" },
          }}
        />

        {/* Add Class Button */}
        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={addClass}
            disabled={isClassLoading}
            className="add-class-button"  // Add class for tutorial
            sx={{
              padding: "10px 20px",
              borderRadius: "8px",
              backgroundColor: "#550FCC",
              '&:hover': {
                backgroundColor: "#8338ec",
              },
            }}
          >
            {isClassLoading ? <CircularProgress size={24} /> : "Add Class"}
          </Button>
        </Box>

        {/* Success Alert */}
        {isClassAdded && (
          <Box mt={4}>
            <Alert severity="success">Class added successfully! Add another class or proceed to prediction.</Alert>

            {/* Add Another Class Button */}
            <Box mt={2}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setIsClassAdded(false)} 
                sx={{ padding: "10px 20px", borderRadius: "8px" }}
              >
                Add Another Class
              </Button>
            </Box>

            {/* Proceed to Predict Button */}
            <Box mt={2}>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={goToPredict} 
                sx={{ padding: "10px 20px", borderRadius: "8px" }}
              >
                Proceed to Predict
              </Button>
            </Box>
          </Box>
        )}
      </Paper>

      {/* Explanatory Card Below */}
      {showExplanatoryCard && (
        <Card
          sx={{
            width: "90%", // Make the card take 90% of the container width
            maxWidth: "800px", // Set a larger max width
            margin: "2rem auto", // Center the card horizontally and add vertical spacing
            padding: "2rem", // Add consistent padding
            borderRadius: "12px", // Rounded corners for better aesthetics
            backgroundColor: "#f9f9f9",
            position: "relative",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: "center" }}>
            <AddIcon sx={{ fontSize: "3rem", color: "#550FCC" }} />{" "}
            {/* Icon for explanation */}
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mt: 2, mb: 1 }}
            >
              Why add a class?
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
              Adding a class allows your model to learn and recognize different
              categories or objects. Each class represents a label or category
              that your model will use to make predictions based on the data
              provided. By adding meaningful classes, you are enhancing your
              model's ability to understand and categorize images more
              effectively.
            </Typography>
          </CardContent>
        </Card>
      )}

    </Container>
  );
};

export default AddClass;
