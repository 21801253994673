import React from 'react';
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Box,
  useTheme
} from '@mui/material';
import {
  ImageDown,
  Images,
  Palette,
  Layers,
  ImagePlus,
  Scale,
  FileOutput,
  Wand2
} from 'lucide-react';

const DesignActionPanel = ({ onActionClick }) => {
  const theme = useTheme();
  
  const actions = [
    {
      id: 'enhance',
      icon: <ImageDown className="w-5 h-5" />,
      name: 'Enhance Images',
      tooltipTitle: 'Enhance Images', 
      placeholder: 'Enhance these images by optimizing quality, lighting and colors'
    },
    {
      id: 'social',
      icon: <Images className="w-5 h-5" />,
      name: 'Social Media Variants',
      tooltipTitle: 'Social Media Variants',
      placeholder: 'Create optimized variants of these images for Instagram, Facebook, Twitter and LinkedIn'
    },
    {
      id: 'palette',
      icon: <Palette className="w-5 h-5" />,
      name: 'Color Palette', 
      tooltipTitle: 'Generate Color Palette',
      placeholder: 'Analyze these images and generate color palettes with dominant and accent colors'
    },
    {
      id: 'background',
      icon: <Layers className="w-5 h-5" />,
      name: 'Background Tools',
      tooltipTitle: 'Background Tools',
      placeholder: 'Help me remove or replace the backgrounds in these images'
    },
    {
      id: 'composite',
      icon: <ImagePlus className="w-5 h-5" />,
      name: 'Composition',
      tooltipTitle: 'Image Composition',
      placeholder: 'Analyze and suggest improvements for the composition and layout of these images'
    },
    {
      id: 'resize',
      icon: <Scale className="w-5 h-5" />,
      name: 'Bulk Resize',
      tooltipTitle: 'Bulk Resize',
      placeholder: 'Resize these images while maintaining quality and aspect ratio'
    },
    {
      id: 'convert',
      icon: <FileOutput className="w-5 h-5" />,
      name: 'Convert Format',
      tooltipTitle: 'Format Conversion',
      placeholder: 'Convert these images to the most appropriate file formats'
    }
   ];

  return (
    <Box sx={{ 
      position: 'fixed', 
      bottom: 24, 
      right: 24,
      '& .MuiSpeedDial-fab': {
        width: 56,
        height: 56,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        }
      },
      '& .MuiSpeedDialAction-fab': {
        margin: '4px 0'  // Add vertical spacing between actions
      }
    }}>
      <SpeedDial
        ariaLabel="Design Actions"
        icon={<SpeedDialIcon icon={<Wand2 className="w-6 h-6" />} />}
        direction="up"
        FabProps={{
          sx: {
            boxShadow: theme.shadows[8]
          }
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.id}
            icon={action.icon}
            tooltipTitle={action.tooltipTitle}
            onClick={() => onActionClick(action)}
            FabProps={{
              sx: {
                width: 40,
                height: 40,
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: theme.palette.grey[100]
                }
              }
            }}
            sx={{
              '& .MuiSpeedDialAction-staticTooltipLabel': {
                whiteSpace: 'nowrap',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                color: 'white',
                fontSize: '0.75rem',
                padding: '4px 8px',
                borderRadius: '4px'
              }
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default DesignActionPanel;