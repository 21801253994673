import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../api";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
  Box,
  Alert, // Add Alert for showing message
  Snackbar // Add Snackbar for showing message
} from "@mui/material";

const ListModels = ({ setModelId, setModelName }) => {
  const [models, setModels] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [shareUsername, setShareUsername] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [showMessage, setShowMessage] = useState(false);

  // Fetch models from the server
  const fetchModels = async () => {
    try {
      const response = await api.get("/models");
      setModels(response.data);
    } catch (error) {
      console.error("Failed to fetch models", error);
    }
  };

  // Show message if redirected from predict page
  useEffect(() => {
    if (location.state?.message) {
      setShowMessage(true);
    }
  }, [location.state]);

  // Handle model selection and navigation
  const handleModelSelect = (modelId, modelName) => {
    setModelId(modelId);
    setModelName(modelName);
    
    // If there's a returnTo path in the state, navigate there
    if (location.state?.returnTo) {
      navigate(location.state.returnTo);
    } else {
      navigate('/predict');
    }
  };

  // Open the share dialog for the selected model
  const handleOpenShareDialog = (modelId) => {
    setSelectedModelId(modelId);
    setShareDialogOpen(true);
  };

  // Open the delete confirmation dialog for the selected model
  const handleOpenDeleteDialog = (modelId) => {
    setSelectedModelId(modelId);
    setDeleteDialogOpen(true); // Open delete dialog
  };

  // Close the delete confirmation dialog without deleting
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // Confirm delete model by ID
  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/models/${selectedModelId}`);
      alert("Model deleted successfully");
      fetchModels();
      setDeleteDialogOpen(false); // Close the delete dialog after deletion
    } catch (error) {
      alert("Failed to delete model");
      console.error("Delete error:", error);
    }
  };

  // Share the model with the entered username
  const handleShare = async () => {
    try {
      const formData = new FormData();
      formData.append("target_username", shareUsername);

      await api.post(`/models/${selectedModelId}/share`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Model shared successfully!");
      setShareDialogOpen(false);
      setShareUsername("");
    } catch (error) {
      console.error("Failed to share model", error);
    }
  };

  useEffect(() => {
    fetchModels();
  }, []);

  return (
    <Container maxWidth="md" sx={{ marginTop: "4rem", padding: "1rem" }}>
      {/* Add Snackbar for showing message */}
      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowMessage(false)} 
          severity="info"
          sx={{ width: '100%' }}
        >
          {location.state?.message}
        </Alert>
      </Snackbar>

      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", marginBottom: "1.5rem", color: "#550FCC" }}
      >
        List of Models
      </Typography>

      {models.length === 0 ? (
        <Typography variant="body1" color="textSecondary" sx={{ textAlign: "center" }}>
          No models available.
        </Typography>
      ) : (
        <List>
          {models.map((model) => (
            <ListItem key={model.id} divider>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <ListItemText
                    primary={
                      <Typography variant="h6" color="textPrimary" sx={{ fontSize: "1.5rem" }}>
                        {model.name} {model.shared ? "(Shared)" : ""}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem", color: "#555" }}>
                          Description: {model.description} | Classes: {model.class_count}
                        </Typography>
                        {model.class_details && (
                          <Typography variant="body2" sx={{ fontSize: "1rem", color: "#999", marginTop: "0.5rem" }}>
                            {Object.entries(model.class_details).map(([className, imageCount]) => (
                              <span key={className}>
                                Class: {className}, Images: {imageCount}<br />
                              </span>
                            ))}
                          </Typography>
                        )}
                      </>
                    }
                  />
                </Grid>

                {/* Buttons Group */}
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr", // Full width for each row
                      gap: "10px",
                    }}
                  >
                    {/* First Row: Predict and Add Class Buttons */}
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ flexGrow: 1 }}
                        onClick={() => handleModelSelect(model.id, model.name)}
                      >
                        Select Model
                      </Button>

                      <Button
                        variant="contained"
                        onClick={() => {
                          setModelId(model.id);
                          setModelName(model.name);
                          navigate("/add-class");
                        }}
                      >
                        Add Class
                      </Button>
                    </Box>

                    {/* Second Row: Full-width Inference Button */}
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => {
                        setModelId(model.id);
                        navigate(`/models/${model.id}/inferences`);
                      }}
                    >
                      Inferences
                    </Button>

                    {/* Third Row: Share and Delete Buttons */}
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={!model.is_owner}
                        sx={{
                          flexGrow: 1,
                          boxShadow: model.is_owner ? "" : "none",
                          opacity: model.is_owner ? 1 : 0.6,
                        }}
                        onClick={() => handleOpenShareDialog(model.id)}
                      >
                        Share
                      </Button>

                      <Button
                        variant="contained"
                        color="error"
                        disabled={!model.is_owner}
                        sx={{
                          flexGrow: 1,
                          boxShadow: model.is_owner ? "" : "none",
                          opacity: model.is_owner ? 1 : 0.6,
                        }}
                        onClick={() => handleOpenDeleteDialog(model.id)} // Open delete dialog on click
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      )}

      {/* Share Dialog */}
      <Dialog open={shareDialogOpen} onClose={() => setShareDialogOpen(false)}>
        <DialogContent>
          <TextField
            fullWidth
            label="Enter username to share with"
            value={shareUsername}
            onChange={(e) => setShareUsername(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShare} color="primary">
            Share
          </Button>
          <Button onClick={() => setShareDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this model? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ListModels;
