import React from 'react';
import {
  DialogTitle,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const ConversationHistory = ({ onSelectConversation, onClose, chats = [] }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ChatBubbleOutlineIcon />
          <Typography variant="h6">Past Conversations</Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0, height: '60vh' }}>
        <List sx={{ p: 0 }}>
          {chats.map((chat, index) => (
            <React.Fragment key={chat.session_id}>
              <ListItemButton 
                onClick={() => onSelectConversation(chat.session_id)}
                sx={{ py: 2 }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {new Date(chat.updated_at).toLocaleDateString()}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {chat.message_count} messages
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography 
                      variant="body2" 
                      color="text.primary"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                      }}
                    >
                      {chat.last_message}
                    </Typography>
                  }
                />
              </ListItemButton>
              {index < chats.length - 1 && <Divider />}
            </React.Fragment>
          ))}

          {chats.length === 0 && (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center', 
              py: 6, 
              px: 2 
            }}>
              <ChatBubbleOutlineIcon sx={{ fontSize: 48, color: 'grey.300', mb: 2 }} />
              <Typography color="text.secondary" align="center">
                No conversations yet. Start a new chat to begin.
              </Typography>
            </Box>
          )}
        </List>
      </DialogContent>
    </>
  );
};

export default ConversationHistory;