import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  CircularProgress,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Search, Sparkles } from 'lucide-react';
import AuthContext from "../context/AuthContext";

// Replace with your own background image
import heroBackgroundImage from "../assets/monet1.png";

const Home = ({ setMode }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Add this state at the top of your component
const [displayText, setDisplayText] = useState("");
const words = ["Search", "Generate", "Classify", "Tag", "Organize"];
const [wordIndex, setWordIndex] = useState(0);
const [isDeleting, setIsDeleting] = useState(false);

// Replace the previous useEffect with this faster typing animation effect
useEffect(() => {
  const currentWord = words[wordIndex];
  const updateText = () => {
    setDisplayText(current => {
      if (!isDeleting && current === currentWord) {
        // Word is complete, wait before deleting
        setTimeout(() => setIsDeleting(true), 800); // Reduced wait time
        return current;
      }
      if (isDeleting && current === "") {
        // Word is deleted, move to next word
        setIsDeleting(false);
        setWordIndex((prev) => (prev + 1) % words.length);
        return "";
      }
      
      if (isDeleting) {
        return current.slice(0, -1);
      }
      return currentWord.slice(0, current.length + 1);
    });
  };

  // Faster typing speed: 50ms for deleting, 80ms for typing
  const timeout = setTimeout(updateText, isDeleting ? 50 : 80);
  return () => clearTimeout(timeout);
}, [displayText, isDeleting, wordIndex]);

  const handleTryNetraAI = () => {
    setLoading(true);
    navigate('/public-features');
    setLoading(false);
  };

  const colors = {
    primary: "#550FCC",
    secondary: "#8338ec",
    accent: "#ff006e",
    text: "#2b2d42",
  };

  // Applications data
  const applications = [
    {
      icon: "📸",
      title: "Photographers",
      description: "Seamlessly tag and organize shoots. AI helps you find any photo in seconds and even create new visuals.",
    },
    {
      icon: "🛍️",
      title: "Retail & E-commerce",
      description: "Automatically tag, classify, and enhance product visuals, and generate marketing images that convert.",
    },
    {
      icon: "🏠",
      title: "Real Estate",
      description: "Sort property images, remove backgrounds, and generate virtual tours—all from a single AI-driven solution.",
    },
    {
      icon: "🎨",
      title: "Creative Agencies",
      description: "Focus on creativity while AI manages your asset library and instantly generates social-ready visuals.",
    },
  ];

  // Use cases data
  const useCases = [
    {
      title: "E-commerce",
      description: "Auto-tagging & classification of product images for frictionless customer discovery.",
    },
    {
      title: "Real Estate",
      description: "Identify property features & auto-generate curated galleries for clients.",
    },
    {
      title: "Advertising",
      description: "Quickly generate campaign visuals, tailor images for social media, and drive engagement.",
    },
    {
      title: "Social Media Marketing",
      description: "Instantly produce platform-optimized images & captions to boost brand visibility.",
    },
  ];

  return (
    <Container maxWidth={false} disableGutters>
      {/* Hero Section */}
<Box
  sx={{
    width: "100%",
    height: { xs: "90vh", md: "100vh" },
    backgroundImage: `url(${heroBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat", // Added to prevent repeating
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    transition: "none", // Prevent any automatic transitions
    transformStyle: "preserve-3d", // Remove if not needed
    backfaceVisibility: "hidden", // Prevent background flickering
    WebkitBackfaceVisibility: "hidden", // For Safari support
    "&::before": {
      content: '""',
      position: "absolute",
      inset: 0,
      background: "linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.5))",
      backdropFilter: "blur(1px)",
      transition: "none", // Prevent overlay transitions
    },
  }}
>
  <Box
    sx={{
      position: "relative",
      zIndex: 2,
      color: "#FDFBF7",
      maxWidth: "1200px",
      textAlign: "center",
      p: { xs: 2, md: 4 },
      animation: "fadeIn 1s ease-out", // Added fade-in animation
      "@keyframes fadeIn": {
        from: { opacity: 0, transform: "translateY(20px)" },
        to: { opacity: 1, transform: "translateY(0)" },
      },
    }}
  >
    <Typography
  variant="h2"
  sx={{
    fontWeight: 700,
    mb: 3,
    lineHeight: 1.2,
    fontSize: { xs: "2rem", sm: "2.5rem", md: "3.75rem" },
    textShadow: "0 2px 4px rgba(0,0,0,0.3)",
    display: "flex",
    flexDirection: "column", // Changed to column to prevent horizontal jumping
    alignItems: "center",
    gap: 2,
  }}
>
  <Box
    sx={{
      height: "60px", // Fixed height to prevent jumping
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      minWidth: "200px",
      color: "white",
      "&::after": {
        content: '""',
        position: "absolute",
        right: "-4px",
        top: "50%",
        transform: "translateY(-50%)",
        width: "2px",
        height: "70%",
        backgroundColor: "#fff",
        animation: "blink 0.8s infinite",
      },
      "@keyframes blink": {
        "0%, 100%": { opacity: 1 },
        "50%": { opacity: 0 },
      },
    }}
  >
    {displayText}
  </Box>
  <Box
    component="span"
    sx={{
      color: "#fff", // Changed back to pure white
      textAlign: "center", // Center align the text
    }}
  >
    Images with the Magic of Multimodal AI
  </Box>
</Typography>

{/* Update the description Typography to also be center-aligned */}
<Typography
  variant="h5"
  sx={{
    mb: 4,
    fontSize: { xs: "1.1rem", md: "1.4rem" },
    mx: "auto",
    maxWidth: "800px",
    opacity: 0.9,
    fontWeight: 300,
    lineHeight: 1.6,
    textAlign: "center",
    color: "#fff",
  }}
>
  Automatically tag, classify, search, and even create new images.
  <br />
  Experience the ultimate AI-driven image management and generation solution.
</Typography>

    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Button
        variant="contained"
        onClick={handleTryNetraAI}
        sx={{
          fontSize: { xs: "1rem", md: "1.2rem" },
          px: 4,
          py: 1.75,
          borderRadius: "50px",
          background: "linear-gradient(45deg, #ff006e, #ff4d4d)",
          fontWeight: 600,
          textTransform: "none",
          boxShadow: "0 4px 15px rgba(255, 0, 110, 0.3)",
          "&:hover": {
            background: "linear-gradient(45deg, #ff4d4d, #ff006e)",
            transform: "translateY(-2px)",
            boxShadow: "0 6px 20px rgba(255, 0, 110, 0.4)",
          },
          transition: "all 0.3s ease",
        }}
      >
        {loading ? (
          <CircularProgress size={24} sx={{ color: "white" }} />
        ) : (
          <>
            Try Netra.AI Free
            <Box
              component="span"
              sx={{
                ml: 1,
                fontSize: "1.2em",
                animation: "bounce 1s infinite",
                display: "inline-block",
                "@keyframes bounce": {
                  "0%, 100%": { transform: "translateX(0)" },
                  "50%": { transform: "translateX(3px)" },
                },
              }}
            >
              →
            </Box>
          </>
        )}
      </Button>

      <Typography
        variant="body1"
        sx={{
          color: "rgba(255,255,255,0.8)",
          fontSize: "0.9rem",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box component="span" sx={{ fontSize: "1.2em" }}>✨</Box>
        No credit card required
      </Typography>
    </Box>
  </Box>
</Box>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 6, md: 10 } }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: colors.primary,
            mb: 6,
            textAlign: "center",
            fontSize: { xs: "2rem", md: "2.5rem" },
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "60px",
              height: "4px",
              backgroundColor: colors.accent,
              borderRadius: "2px",
            },
          }}
        >
          All-in-One AI Image Platform
        </Typography>

        <Grid container spacing={4}>
          {/* Feature 1: Organize & Discover */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                p: 3,
                borderRadius: "16px",
                boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                },
              }}
            >
              <Box
                sx={{
                  background: "linear-gradient(135deg, #8338ec, #550FCC)",
                  borderRadius: "12px",
                  width: "fit-content",
                  p: 2,
                  mb: 3,
                }}
              >
                <Search className="w-10 h-10 text-white" />
              </Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 700, mb: 1, color: colors.text }}
              >
                Organize & Discover
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: colors.text }}>
                Let AI handle the heavy lifting—instant tagging, classification, and
                intelligent search for all your images.
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: colors.text, lineHeight: 1.6 }}
              >
                • AI-powered image classification <br />
                • Smart text & visual search <br />
                • Intelligent organization at scale
              </Typography>
            </Card>
          </Grid>

          {/* Feature 2: Create & Generate */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                p: 3,
                borderRadius: "16px",
                boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                },
              }}
            >
              <Box
                sx={{
                  background: "linear-gradient(135deg, #ff4d4d, #ff006e)",
                  borderRadius: "12px",
                  width: "fit-content",
                  p: 2,
                  mb: 3,
                }}
              >
                <Sparkles className="w-10 h-10 text-white" />
              </Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 700, mb: 1, color: colors.text }}
              >
                Create & Generate
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: colors.text }}>
                Transform ideas into visuals with powerful text-to-image tools.
                Enhance or edit pictures with AI in a single click.
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: colors.text, lineHeight: 1.6 }}
              >
                • AI-powered image generation <br />
                • Automatic editing & enhancement <br />
                • Unlimited creative possibilities
              </Typography>
            </Card>
          </Grid>

          {/* Feature 3: Custom Models */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                p: 3,
                borderRadius: "16px",
                boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                },
              }}
            >
              <Box
                sx={{
                  background: "linear-gradient(135deg, #00b4d8, #0077b6)",
                  borderRadius: "12px",
                  width: "fit-content",
                  p: 2,
                  mb: 3,
                }}
              >
                <Box className="w-10 h-10 text-white">🎯</Box>
              </Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 700, mb: 1, color: colors.text }}
              >
                Custom Models
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: colors.text }}>
                Build your own image classification models in minutes. Train,
                deploy, and start using them right away.
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: colors.text, lineHeight: 1.6 }}
              >
                • Quick model creation <br />
                • Easy class management <br />
                • Instant, accurate predictions
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Design Copilot Highlight Section */}
      <Box sx={{ backgroundColor: "#fff", py: { xs: 6, md: 10 } }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  color: colors.primary,
                  mb: 3,
                  fontSize: { xs: "2rem", md: "2.5rem" },
                }}
              >
                Meet Your AI Design Copilot
              </Typography>
              <Typography variant="h6" sx={{ color: colors.text, mb: 3 }}>
                An AI-powered consultant that elevates your images. Collaborate
                via simple chat commands to optimize, generate, or enhance your
                creative assets instantly.
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Box
                      sx={{
                        backgroundColor: colors.accent,
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        mr: 2,
                      }}
                    >
                      💡
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ mb: 0.5, color: colors.text }}
                      >
                        Intelligent Conversation
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Get design feedback & suggestions within a chat interface.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Box
                      sx={{
                        backgroundColor: colors.secondary,
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        mr: 2,
                      }}
                    >
                      ✨
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ mb: 0.5, color: colors.text }}
                      >
                        One-Click Enhancements
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Automate resizing, cropping, and color adjustments for
                        perfect images.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        backgroundColor: colors.primary,
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        mr: 2,
                      }}
                    >
                      🎯
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ mb: 0.5, color: colors.text }}
                      >
                        Social-Ready Variants
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Automatically generate platform-optimized versions for
                        Instagram, Facebook, Twitter, and more.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -20,
                    right: -20,
                    width: "100%",
                    height: "100%",
                    borderRadius: "16px",
                    background: "linear-gradient(135deg, #550FCC, #8338ec)",
                    zIndex: 0,
                  },
                }}
              >
                <Card
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    borderRadius: "16px",
                    p: 3,
                    boxShadow: "0 15px 35px rgba(0,0,0,0.1)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      backgroundColor: "#f8f9fa",
                      borderRadius: "8px",
                      p: 2,
                      mb: 2,
                    }}
                  >
                    <Typography variant="body1" sx={{ color: colors.text }}>
                      “I need a professional version of this product shot, plus a
                      quick social media teaser.”
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      backgroundColor: colors.primary,
                      borderRadius: "8px",
                      p: 2,
                      color: "white",
                    }}
                  >
                    <Typography variant="body1">
                      “Sure! I'll enhance the lighting, remove the background, and
                      create an Instagram-ready version. Ready in 10 seconds.”
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Applications Section */}
      <Box sx={{ backgroundColor: "#f8f9fa", py: { xs: 6, md: 10 } }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              color: colors.primary,
              mb: 6,
              textAlign: "center",
              fontSize: { xs: "2rem", md: "2.5rem" },
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "60px",
                height: "4px",
                backgroundColor: colors.accent,
                borderRadius: "2px",
              },
            }}
          >
            Transform Your Industry with AI
          </Typography>
          <Grid container spacing={4}>
            {applications.map((app, idx) => (
              <Grid item xs={12} sm={6} md={3} key={idx}>
                <Card
                  sx={{
                    height: "100%",
                    borderRadius: "16px",
                    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "translateY(-8px)",
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      p: 3,
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        mb: 2,
                        fontSize: "3rem",
                      }}
                    >
                      {app.icon}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 700, mb: 1, color: colors.text }}
                    >
                      {app.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary", lineHeight: 1.6 }}
                    >
                      {app.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Use Cases Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 6, md: 10 } }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: colors.primary,
            mb: 6,
            textAlign: "center",
            fontSize: { xs: "2rem", md: "2.5rem" },
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "60px",
              height: "4px",
              backgroundColor: colors.accent,
              borderRadius: "2px",
            },
          }}
        >
          Real-World Use Cases
        </Typography>
        <Grid container spacing={4}>
          {useCases.map((useCase, idx) => (
            <Grid item xs={12} sm={6} md={3} key={idx}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: "16px",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "translateY(-8px)",
                  },
                }}
              >
                <CardContent sx={{ p: 3, textAlign: "center" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 700, mb: 1, color: colors.text }}
                  >
                    {useCase.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "text.secondary", lineHeight: 1.6 }}
                  >
                    {useCase.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Final CTA Section */}
      <Box
        sx={{
          background: 'linear-gradient(135deg, #550FCC, #8338ec)',
          py: { xs: 8, md: 12 },
          color: "white",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'url("data:image/svg+xml,%3Csvg width=\\"20\\" height=\\"20\\" xmlns=\\"http://www.w3.org/2000/svg\\"%3E%3Cpath d=\\"M0 0h20v20H0z\\" fill=\\"%23ffffff\\" fill-opacity=\\"0.05\\"/%3E%3C/svg%3E")',
            opacity: 0.1,
          },
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ maxWidth: "800px", mx: "auto", position: "relative", zIndex: 1 }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 700,
                mb: 3,
                fontSize: { xs: "2rem", md: "2.5rem" },
              }}
            >
              Ready to Transform Your Image Workflow with AI?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                opacity: 0.9,
                fontSize: { xs: "1.1rem", md: "1.25rem" },
                lineHeight: 1.6,
              }}
            >
              Sign up today and experience the power of Netra.AI for your visual
              content. No credit card required, cancel anytime.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/signup")}
              sx={{
                px: 3,
                py: 1.5,
                fontSize: { xs: "1rem", md: "1.2rem" },
                borderRadius: "50px",
                backgroundColor: "white",
                color: colors.primary,
                textTransform: "none",
                fontWeight: 600,
                boxShadow: "0 4px 15px rgba(255, 255, 255, 0.2)",
                "&:hover": {
                  backgroundColor: colors.accent,
                  color: "white",
                  transform: "translateY(-2px)",
                  boxShadow: "0 6px 20px rgba(255, 255, 255, 0.3)",
                },
                transition: "all 0.3s ease",
              }}
            >
              Start Free Trial
            </Button>
            <Typography variant="body2" sx={{ mt: 2, opacity: 0.8 }}>
              No commitments. Cancel anytime.
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid rgba(0,0,0,0.1)",
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant="body2" sx={{ color: colors.text, opacity: 0.8 }}>
              © {new Date().getFullYear()} Netra.AI. All rights reserved.
            </Typography>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Link
                href="/privacy"
                sx={{
                  color: colors.text,
                  opacity: 0.8,
                  textDecoration: "none",
                  "&:hover": {
                    opacity: 1,
                    color: colors.primary,
                  },
                }}
              >
                Privacy Policy
              </Link>
              <Link
                href="/terms"
                sx={{
                  color: colors.text,
                  opacity: 0.8,
                  textDecoration: "none",
                  "&:hover": {
                    opacity: 1,
                    color: colors.primary,
                  },
                }}
              >
                Terms of Service
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Home;
