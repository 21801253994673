import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { 
  Button, 
  Container, 
  Typography, 
  Box, 
  Card, 
  CardContent, 
  CardActions, 
  Avatar, 
  CircularProgress, 
  Paper, 
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Collapse,
  Divider,
  Grid
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const MultiPredict = ({ modelId, modelName }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showExplanatoryCard, setShowExplanatoryCard] = useState(true);
  const [expandedExplanations, setExpandedExplanations] = useState({});
  const [loadingExplanations, setLoadingExplanations] = useState({});
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
    setPredictions(predictions.filter((_, i) => i !== index));
  };

  const predict = async () => {
    if (!modelId) {
      navigate('/list-models', {
        state: {
          returnTo: '/predict',
          message: 'Please select a model first'
        }
      });
      return;
    }

    if (files.length === 0) {
      alert("Please upload at least one image file.");
      return;
    }

    setLoading(true);
    setPredictions([]);

    try {
      const results = await Promise.all(files.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await api.post(`/models/${modelId}/predict`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          return {
            fileName: file.name,
            predictions: response.data.predictions,
            error: null,
            explanation: null
          };
        } catch (error) {
          return {
            fileName: file.name,
            predictions: [],
            error: error.message,
            explanation: null
          };
        }
      }));

      setPredictions(results);
    } catch (error) {
      alert("Error during predictions: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const getExplanation = async (index) => {
    if (!predictions[index] || !predictions[index].predictions.length) {
      return;
    }

    // Set loading state for this specific explanation
    setLoadingExplanations(prev => ({ ...prev, [index]: true }));

    const [predictedClass, confidence] = predictions[index].predictions[0];
    const formData = new FormData();
    formData.append("predicted_class", predictedClass);
    formData.append("confidence", confidence);
    formData.append("file", files[index]);

    try {
      const response = await api.post(`/models/${modelId}/explanation`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setPredictions(prev =>
        prev.map((pred, i) =>
          i === index ? { ...pred, explanation: response.data.explanation } : pred
        )
      );
    } catch (error) {
      alert("Error getting explanation: " + error.message);
    } finally {
      // Clear loading state for this explanation
      setLoadingExplanations(prev => ({ ...prev, [index]: false }));
    }
  };

  const toggleExplanation = (index) => {
    setExpandedExplanations(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const resetAll = () => {
    setFiles([]);
    setPredictions([]);
    setExpandedExplanations({});
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "1rem",
        marginTop: "6rem",
      }}
    >
      <Paper elevation={3} sx={{ padding: "2rem", width: "100%", borderRadius: "12px", mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#550FCC", mb: 4 }}>
          Predict Classes for Model: {modelName}
        </Typography>

        <Box sx={{ mb: 3 }}>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ marginBottom: "1rem" }}
          />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={predict}
              disabled={loading || files.length === 0}
              sx={{
                padding: "10px 20px",
                borderRadius: "8px",
                backgroundColor: "#550FCC",
                '&:hover': {
                  backgroundColor: "#8338ec",
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Predict All"}
            </Button>

            {files.length > 0 && (
              <Button
                variant="outlined"
                onClick={resetAll}
                disabled={loading}
                sx={{ borderRadius: "8px" }}
              >
                Reset All
              </Button>
            )}
          </Box>
        </Box>

        {/* File List */}
        {files.length > 0 && (
          <List>
            {files.map((file, index) => (
              <ListItem key={`${file.name}-${index}`}>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <IconButton 
                    edge="end" 
                    aria-label="delete"
                    onClick={() => removeFile(index)}
                    disabled={loading}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}

        {/* Predictions */}
        {predictions.length > 0 && (
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {predictions.map((result, index) => (
              <Grid item xs={12} key={`prediction-${index}`}>
                <Card sx={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      Results for: {result.fileName}
                    </Typography>

                    {result.error ? (
                      <Typography color="error" sx={{ mt: 2 }}>
                        Error: {result.error}
                      </Typography>
                    ) : (
                      <>
                        <List>
                          {result.predictions.map(([className, confidence], predIndex) => (
                            <ListItem key={predIndex}>
                              <ListItemText
                                primary={
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Chip
                                      label={`${(confidence * 100).toFixed(2)}%`}
                                      color={predIndex === 0 ? "primary" : "default"}
                                      size="small"
                                    />
                                    <Typography>
                                      {className}
                                    </Typography>
                                    {predIndex === 0 && (
                                      <Chip
                                        label="Top Prediction"
                                        variant="outlined"
                                        size="small"
                                        sx={{ ml: 'auto' }}
                                      />
                                    )}
                                  </Box>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>

                        {result.predictions.length > 0 && (
                          <>
                            <Divider sx={{ my: 2 }} />
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => getExplanation(index)}
                                startIcon={loadingExplanations[index] ? <CircularProgress size={20} /> : <QuestionAnswerIcon />}
                                disabled={loading || loadingExplanations[index]}
                              >
                                {loadingExplanations[index] ? "Getting Explanation..." : "Get Explanation"}
                              </Button>

                              {result.explanation && (
                                <IconButton
                                  onClick={() => toggleExplanation(index)}
                                  sx={{
                                    transform: expandedExplanations[index] ? 'rotate(180deg)' : 'none',
                                    transition: 'transform 0.2s'
                                  }}
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              )}
                            </Box>

                            <Collapse in={expandedExplanations[index]}>
                              {result.explanation && (
                                <Paper sx={{ p: 2, mt: 2, bgcolor: 'grey.50' }}>
                                  <Typography variant="body2">
                                    {result.explanation}
                                  </Typography>
                                </Paper>
                              )}
                            </Collapse>
                          </>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>

      {/* Help Card */}
      {showExplanatoryCard && (
        <Card sx={{
          width: "90%",
          maxWidth: "800px",
          margin: "2rem auto",
          padding: "2rem",
          borderRadius: "12px",
          backgroundColor: "#f9f9f9",
          position: "relative",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}>
          <IconButton
            aria-label="close"
            onClick={() => setShowExplanatoryCard(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: "center" }}>
            <HelpOutlineIcon sx={{ fontSize: "3rem", color: "#550FCC" }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              How Does Multi-Image Prediction Work?
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ lineHeight: 1.6 }}>
              You can now upload multiple images at once to get predictions for each one.
              The model will process each image individually and provide predictions with
              confidence scores. For the top prediction of each image, you can request
              an explanation of why the model made that specific prediction.
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default MultiPredict;